@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");

@font-face {
  font-family: Gabriela-Regular;
  src: url("../../font/Gabriela-Regular.ttf");
}

@font-face {
  font-family: Gabriela-Semibold;
  src: url("../../font/Gabriela-SemiBold.ttf");
}

:root {
  --primary-color: #ffe4ae;
}

.banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  margin-bottom: 20px;
}

.banner h1 {
  font-size: 35px;
  font-family: "Gabriela-Semibold" !important;
  color: #fff;
}

.edu-breadcrumb ol {
  background: transparent;
  justify-content: center;
  padding: 0;
}

.edu-breadcrumb li a,
.edu-breadcrumb li,
.edu-breadcrumb li::before {
  color: var(--primary-color) !important;
  font-family: "Open Sans";
  font-weight: 600;
}

.info-box {
  background-color: var(--primary-color);
}

.info-box h3 {
  font-family: "Open Sans" !important;
  font-weight: 500;
  font-size: 26px;
  line-height: 50px;
  width: 80%;
  margin: 0px auto !important;
  padding: 50px 0;
}

.education-box {
  position: relative;
  height: 420px;
  overflow: hidden;
}

.education-box > img {
  width: 100%;
  transition: 0.3s all ease-in-out;
  height: 100%;
  object-fit: cover;
}

.education-box .content {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.education-box .content h2 {
  color: #fff;
  font-size: 24px;
  font-family: "Gabriela-Semibold" !important;
}

.education-box .content a.viewmore {
  font-size: 16px;
  color: #fff;
  margin-top: 10px;
  transition: 0.3s all ease-in-out;
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.education-box:hover .content a.viewmore {
  color: var(--primary-color);
}

.education-box .content a.viewmore:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0%;
  background: var(--primary-color);
  transition: 0.3s all ease-in-out;
}

.education-box:hover .content a.viewmore:before {
  width: 100%;
}

.education-box .content img {
  width: 34px;
  height: 34px;
  padding-top: 7px;
  filter: blur(0px);
}

.education-box:hover > img {
  transform: scale(1.1);
  filter: blur(3px);
}

.education-boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  margin-bottom: 20px;
}

.education-box:nth-child(2) {
  grid-row: 1 / 3;
  grid-column: 2;
  height: 100%;
}

.education-box:nth-child(2) .content {
  bottom: auto;
  top: 50%;
  transform: translate(-50%, -50%);
}

.education-box:nth-child(2) img {
  height: 100%;
  max-height: 855px;
}

.more_help_wrapper {
  height: 380px;
  background: url("../../images/education-web/help.png") no-repeat top
    right/cover;
  margin: 90px 0 0;
}

.more_help_wrapper h2 {
  font-size: 35px;
  color: #fff;
  margin-bottom: 15px;
}

.more_help_wrapper p {
  color: #fff;
  font-size: 14px;
}

.edu-btn {
  background: var(--primary-color);
  color: #000;
  border-radius: 0;
  width: 190px;
  height: 47px;
  line-height: 47px;
  padding: 0;
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px;
}

.content-wrapper {
  padding: 70px 0;
  scroll-behavior: smooth;
}

.content-wrapper .heading {
  font-size: 35px;
  scroll-margin-top: 100px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.content-wrapper img {
  max-width: 100%;
}

.sub-heading {
  font-size: 28px;
  margin-bottom: 10px;
}

.content-wrapper p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 23px;
  font-family: "Open Sans";
}

@media only screen and (min-width: 1366px) {
  .content-wrapper .container {
    max-width: 1280px !important;
  }

  .edu-container {
    max-width: 1280px !important;
  }
}

.table-of-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: space-between;
  grid-gap: 12px;
}

.table-of-content li {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  transition: 0.3s all ease-in-out;
  position: relative;
  overflow: hidden;
}

.table-of-content li a {
  color: #000;
  display: block;
  width: 78%;
}

.table-of-content li h4 {
  font-family: "Gabriela-Semibold" !important;
  font-size: 16px;
}

.table-of-content li p {
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 18px;
  margin: 0;
}

.table-of-content li img {
  position: absolute;
  top: 50%;
  right: -100%;
  transform: translateY(-50%);
  transition: 0.3s right ease-in-out;
}

.table-of-content li:hover,
.table-of-content li.is-current {
  background-color: var(--primary-color);
}

.table-of-content li:hover img,
.table-of-content li.is-current img {
  right: 0;
}

html {
  scroll-behavior: smooth;
}

.sticky-breadcrumb {
  position: sticky;
  top: 120px;
  width: 320px;
  margin-left: 15px;
}

.sticky-breadcrumb h4 {
  font-family: "Open Sans" !important;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
}

.sticky-breadcrumb li a {
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.sticky-breadcrumb li {
  padding: 7px 10px;
  border-left: 4px solid #fff;
}

.sticky-breadcrumb li:hover {
  background-color: #fbfbfb;
}

.sticky-breadcrumb li.is-current {
  border-color: var(--primary-color);
  background-color: #fbfbfb;
}

.sticky-breadcrumb li.is-current a {
  text-transform: uppercase;
  font-weight: 700;
}

ol li h6 {
  font-family: "Open Sans" !important;
  font-weight: 600;
}

.metal-wrapper .row:last-child {
  margin-top: 200px;
}

.metal-wrapper > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ring-type-wrapper h2 {
  font-size: 20px;
}

.bg-dull {
  background-color: #f9f9f9;
}

.grid-section h2 {
  font-size: 24px;
}

.section-overlap {
  margin-top: -400px;
}

.sticky-breadcrumb {
  margin-top: 100px;
}

.diamond-types img {
  width: 60px;
  height: 60px;
}

.diamond-tabs {
  border: none;
}

.diamond-tabs li button {
  background: transparent !important;
  border: 0 !important;
  font-size: 20px;
  font-family: "Roboto Condensed";
  color: #808080;
  text-transform: uppercase;
  position: relative;
  padding: 0;
  transform: scale(0.8, 1);
}

.diamond-tabs li button::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.diamond-tabs li button.active::after {
  background: #000;
  outline: 2px solid var(--primary-color);
}

.diamond-tabs li button.active {
  font-weight: 600;
  color: #000 !important;
  -webkit-text-stroke: 1px var(--primary-color);
  transform: scale(1.05);
}

.diamond-tabs li {
  position: absolute;
}

.diamond-tabs li:first-child {
  top: -30px;
  left: 210px;
}

.diamond-tabs li:nth-child(2) {
  left: 400px;
  top: 50%;
  transform: translateY(-50%);
}

.diamond-tabs li:nth-child(3) {
  bottom: -25px;
  left: 200px;
}

.diamond-tabs li:last-child {
  top: 50%;
  transform: translateY(-50%);
  left: -15px;
}

.diamond-tabs-wrapper > img {
  margin: 0 70px;
}

.diamond-tabs-wrapper > .tab-content {
  width: 62%;
  align-self: center;
  margin-left: auto;
  min-height: 350px;
}

.section-overlap.loose-diamond-page {
  margin-top: 0;
}

.education-tabs h3 {
  font-weight: 600;
  font-size: 20px;
  font-family: "Open Sans" !important;
  color: #b3b3b3;
  margin: 0;
}

.education-tabs li.active h3 {
  color: #000;
}

.education-tabs li a {
  text-align: center;
}

.education-tabs li img {
  width: 20px;
  height: 20px;
  display: block;
  margin: 0 auto 5px;
}

.education-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
}

.education-tabs li {
  padding: 0 30px 20px;
  border-bottom: 2px solid #ddd;
  position: relative;
}

.education-tabs li::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 1px;
  background: #ddd;
}

.education-tabs li:last-child:after {
  display: none;
}

.education-tabs li.active {
  border-color: #000;
}

.education-tabs li.active img {
  filter: invert(83%) sepia(32%) saturate(319%) hue-rotate(354deg)
    brightness(103%) contrast(102%);
}

.blog-breadcrumb h1 {
  font-size: 32px;
}

.blog-breadcrumb li a,
.single-blog-breadcrumb li a {
  font-size: 12px !important;
  font-weight: 300 !important;
}

.blog-breadcrumb li p,
.single-blog-breadcrumb li p {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #000 !important;
}

.blog-item {
  margin-bottom: 25px;
  transition: 0.3s transform ease-in-out;
  position: relative;
}

.blog-item > span > img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin: 0 !important;
  transition: 0.3s transform ease-in-out;
}

.blog-item:hover img {
  transform: scale(1.1);
}

.blog-item > span {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;
}

.blog-item .content {
  background-color: #fff !important;
  width: 300px;
  margin: -60px 0 0;
  padding: 15px 12px;
  position: relative;
  transition: 0.3s transform ease-in-out;
}

.blog-item:hover .content {
  transform: translateY(-2px);
}

.blog-item:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 300px;
  height: 2px;
  background-color: #000;
  z-index: -1;
}

.blog-item .category {
  background-color: #000;
  color: #fff;
  font-size: 12px;
  font-family: "Roboto" !important;
  letter-spacing: 0.5px;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 3px;
  text-transform: uppercase;
  margin: 0 !important;
}

.blog-item .author {
  font-size: 12px !important;
  text-align: right;
  color: #bbb;
  letter-spacing: 0.5px;
}

.blog-item .content > a {
  font-size: 23px;
  letter-spacing: 0;
  color: #000;
  line-height: 1.3;
  font-family: "MTD AlFresco" !important;
  /* font-weight: bold; */
  margin-bottom: 10px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.blog-item .content > p {
  color: #bbb;
  font-size: 12px;
  font-weight: 500;
}

.blog-item .content a img {
  transform: scale(0);
  transition: 0.3s transform ease-in-out;
}

.blog-item:hover .content a img {
  transform: scale(1);
}
.Blog_heading_Ld {
  font-size: 23px;
  font-family: MTD AlFresco !important;
}
.pagination-blog {
  list-style: none !important;
  padding: 0 !important;
  grid-gap: 10px !important;
  margin-top: 15px !important;
}

.pagination-blog .page-item a,
.pagination-blog .page-item.active .page-link {
  border: 1px solid #f1f1f1;
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 0px !important;
  text-align: center;
  line-height: 32px;
  box-shadow: none !important;
}

.pagination-blog .page-item.active .page-link {
  background-color: #000;
}

.single-blog-breadcrumb ol,
.blog-breadcrumb ol {
  justify-content: center;
}
.blog-detail h1 {
  font-size: 35px;
  margin: 50px 30px 20px;
}
.blog-detail h2 {
  font-size: 34px;
  margin: 50px 30px 20px;
}

.pub-date {
  font-size: 12px !important;
}

.top-info > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}

.top-info > div h4 {
  font-family: "Roboto" !important;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.top-info {
  grid-gap: 30px;
}

.share_this h6 {
  font-size: 12px;
  font-family: "Roboto" !important;
  text-align: left;
  font-weight: 400;
}

.share_this ul {
  display: flex;
  justify-content: flex-start;
  grid-gap: 6px;
}

.share_this ul li svg {
  fill: #333;
}

.page-link .visually-hidden {
  display: none;
}

.banner h4 {
  margin: 20px 0 0;
  color: #fff;
  font-family: "Open Sans" !important;
  font-weight: 600;
  font-size: 24px;
  font-style: italic;
  width: 50%;
  text-align: center;
  line-height: 1.5;
}

@media only screen and (min-width: 1367px) {
  .loose-diamond-page #section-6 .col-xl-2,
  .loose-diamond-page .last-section .col-xl-2,
  .wedding-rings .last-section .col-xl-2,
  .wedding-rings #section-2 .col-xl-2 {
    display: none;
  }

  .loose-diamond-page #section-6 .col-xl-10,
  .loose-diamond-page .last-section .col-xl-10,
  .wedding-rings .last-section .col-xl-10,
  .wedding-rings #section-2 .col-xl-10 {
    max-width: 100% !important;
    flex: 1;
  }
}

@media only screen and (max-width: 1366px) {
  .main-content .col-xl-8 {
    max-width: 100% !important;
    flex: 1;
  }

  .education-box > img {
    height: 100%;
    object-fit: cover;
  }

  .education-box {
    height: 300px;
  }

  .info-box h3 {
    font-size: 22px;
    line-height: 42px;
    width: 75%;
  }

  .table-of-content {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .sticky-breadcrumb {
    margin-top: 100px;
  }

  .content-wrapper .heading {
    font-size: 30px;
  }

  .banner {
    height: 320px;
  }

  .education-tabs h3 {
    font-size: 18px;
  }

  .education-tabs li {
    padding: 0 30px 20px;
  }

  .sticky-breadcrumb {
    width: auto;
    padding: 0 !important;
  }

  .diamond-tabs-wrapper > img {
    max-width: 23%;
  }

  .diamond-tabs li:first-child {
    top: 5px;
    left: 180px;
  }

  .diamond-tabs li button {
    font-size: 18px;
  }

  .diamond-tabs li button.active {
    transform: scale(1);
  }

  .diamond-tabs li:nth-child(3) {
    bottom: 5px;
    left: 175px;
  }

  .diamond-tabs li:nth-child(2) {
    left: 340px;
  }

  .banner h4 {
    width: 70%;
  }
}

@media only screen and (max-width: 1024px) {
  .education-tabs h3 {
    font-size: 16px;
  }

  .content-wrapper .heading {
    font-size: 28px;
  }

  .diamond-tabs li:last-child {
    left: 0;
  }

  .diamond-tabs li:first-child {
    top: 25px;
    left: 160px;
  }

  .diamond-tabs li:nth-child(3) {
    bottom: 30px;
    left: 155px;
  }

  .diamond-tabs li:nth-child(2) {
    left: 300px;
  }

  .content-wrapper p {
    font-size: 13px;
    line-height: 20px;
  }

  .content-wrapper .heading {
    font-size: 25px;
  }

  .sub-heading {
    font-size: 21px;
  }

  .grid-section h2 {
    font-size: 20px;
  }

  .edu-breadcrumb li a,
  .edu-breadcrumb li,
  .edu-breadcrumb li::before {
    font-size: 10px;
  }

  .education-box {
    height: 220px;
  }

  .education-box .content {
    width: 100%;
  }

  .education-box .content h2 {
    font-size: 20px;
  }

  .banner h4 {
    width: 75%;
    font-size: 20px;
  }
}

@media only screen and (max-width: 993px) {
  .education-tabs li {
    padding: 0 20px 15px;
  }

  .education-tabs h3 {
    font-size: 14px;
  }

  .table-of-content {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .table-of-content li p {
    font-size: 10px;
    line-height: 15px;
  }

  .loose-diamond-page .diamond-tabs-wrapper {
    flex-direction: column;
  }

  .diamond-tabs-wrapper > img {
    max-width: 30%;
  }

  .diamond-tabs li:first-child {
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .diamond-tabs-wrapper > .tab-content {
    width: 100%;
    margin-top: 80px;
  }

  .diamond-tabs li:nth-child(2) {
    left: 480px;
    top: 90px;
    transform: translateY(0);
  }

  .diamond-tabs li:nth-child(3) {
    bottom: auto;
    left: 50%;
    top: 230px;
    transform: translateX(-50%);
  }

  .diamond-tabs li:last-child {
    left: 180px;
    top: 90px;
    transform: translateY(0);
  }

  .diamond-tabs-wrapper > .tab-content {
    margin-top: 80px;
    text-align: center;
    padding: 0 80px;
    min-height: auto;
  }

  div[id*="section-"] {
    margin-top: 30px;
  }

  .more_help_wrapper h2 {
    font-size: 22px;
  }

  .content-wrapper p {
    font-size: 12px;
    line-height: 18px;
  }

  .edu-btn {
    width: 140px;
    height: 35px;
    line-height: 35px;
    font-size: 13px;
  }

  .more_help_wrapper {
    height: 240px;
    margin: 50px 0 0;
  }

  .banner {
    height: 240px;
  }

  .metal-wrapper > img {
    width: 45%;
  }

  .banner h4 {
    width: 80%;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 0;
  }

  .education-box {
    height: 160px;
  }

  .education-box .content img {
    width: 22px;
    height: 22px;
  }

  .education-box .content a.viewmore {
    font-size: 14px;
  }

  .education-box .content h2 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .banner h1 {
    font-size: 28px;
  }

  .education-box .content {
    top: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
  }

  .banner h4 {
    font-size: 14px;
    line-height: 1.3;
    font-weight: 500;
  }

  .education-boxes {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    margin-bottom: 16px;
    padding: 0px 15px;
  }

  .banner {
    margin-bottom: 16px;
  }

  .education-box:nth-child(2) {
    grid-column: 1;
  }
}

@media only screen and (max-width: 600px) {
  .table-of-content {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .content-wrapper .heading {
    font-size: 30px;
  }

  .diamond-tabs-wrapper > .tab-content {
    padding: 0;
  }

  .diamond-tabs-wrapper > img {
    max-width: 60%;
    margin: 0;
  }

  .diamond-tabs li:nth-child(3) {
    top: 245px;
  }

  .diamond-tabs li:nth-child(2) {
    left: 10px;
    top: 100px;
  }

  .diamond-tabs li:last-child {
    left: calc(100% - 60px);
    top: 100px;
  }

  .section-overlap div[class*="col-"] {
    padding: 0;
  }

  .grid-section {
    text-align: center;
  }

  .grid-section p {
    margin: 0 auto;
    width: 95%;
  }

  #section-8 {
    margin-top: 30px;
  }

  .sub-heading {
    margin-top: 20px;
  }

  .more_help_wrapper {
    text-align: center;
    padding: 0 40px !important;
    background-position: 20% 20%;
  }

  .education-tabs {
    flex-wrap: wrap;
    grid-gap: 10px;
  }

  .education-tabs li {
    padding: 0 20px 15px;
    width: 40%;
    border: 2px solid #ddd;
    border-radius: 8px;
  }

  .education-tabs li {
    padding: 0;
    width: 30%;
    border: 2px solid #ddd;
    border-radius: 8px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .education-tabs li::after,
  .metal-wrapper img {
    display: none;
  }

  .engagement-rings #section-1,
  .engagement-rings #section-2,
  .engagement-rings #section-3,
  .engagement-rings #section-4,
  .fine-jewelery #section-2,
  .fine-jewelery #section-3 {
    margin-top: 30px;
  }

  .metal-wrapper > .mt-40,
  .metal-wrapper .row:last-child {
    margin-top: 0;
  }

  .fine-jewelery .main-content .col-xl-8 > .container {
    padding: 0;
  }

  .blog-detail h2 {
    margin: 20px 0 30px;
  }

  .blog-detail p {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .blog_details_page img {
    max-width: 100%;
    width: auto !important;
    height: auto !important;
  }
}
