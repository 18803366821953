.lj_faq_Question_heading {
	font-size: 18px !important;
	line-height: 35px !important;
	color: #C7B064 !important;
	font-weight: 500 !important;
    font-family: 'Playfair Display', serif !important;
}
.lj_faq_Question_ans{
    font-size: 16px !important;
	line-height: 25px !important;
	color: #000000d6 !important;
	font-weight: 400 !important;
    font-family: 'Inter', sans-serif !important;
}
.lj_faq_accordian .css-1iji0d4 {
    padding: 0px 16px !important;
}
.lj_faq_accordian {
    background-color: none!important;
    box-shadow: 0 0 2px #c7b064!important;
    margin-bottom: 15px!important;
}
.lj_faq_accordian > div:first-of-type {
    width: 100% !important;
    justify-content: space-between !important;
    padding: 0 16px;
}
.lj_faq_accordian svg{
    color: #C7B064 !important;
}
.lj_faq_accordian.css-1elwnq4-MuiPaper-root-MuiAccordion-root::before {
	background-color: #c7b0641b !important;
    box-shadow: none !important;
}

@media(min-width:320px) and (max-width:767px){
    .lj_faq_Question_heading {
        font-size: 16px !important;
        line-height: 22px !important;
    }
    .lj_faq_Question_ans {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}
@media(min-width:768px) and (max-width:1024px){
    .lj_faq_Question_heading {
        font-size: 16px !important;
        line-height: 22px !important;
    }
    .lj_faq_Question_ans {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}
@media(min-width:1025px) and (max-width:1199px){
    .lj_faq_Question_heading {
        font-size: 16px !important;
        line-height: 22px !important;
    }
    .lj_faq_Question_ans {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}
@media(min-width:1200px) and (max-width:1440px){
    .lj_faq_Question_heading {
        font-size: 16px !important;
        line-height: 22px !important;
    }
    .lj_faq_Question_ans {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}
